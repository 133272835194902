<template>
    <ts-loading-banner :loading="waiting">
        <div class="tw-space-y-2">
            <div class="tw-flex tw-space-x-3">
                <div
                    class="tw-border tw-h-32 tw-w-48 tw-rounded-lg tw-p-1 tw-bg-white tw-overflow-hidden tw-relative"
                    @mouseover="() => (showBrowseFileBotton = true)"
                    @mouseleave="() => (showBrowseFileBotton = false)"
                >
                    <img
                        :src="logo_file_src"
                        alt=""
                        class="tw-object-contain tw-w-full tw-h-full"
                    />
                    <div
                        class="tw-w-full tw-h-full tw-opacity-30 tw-bg-gray-600 tw-absolute tw-top-0 tw-left-0 tw-transform tw-duration-500"
                        v-show="showBrowseFileBotton"
                    ></div>
                    <div
                        class="tw-w-full tw-h-full tw-absolute tw-top-0 tw-left-0 tw-flex tw-justify-center tw-items-center"
                        v-show="showBrowseFileBotton"
                    >
                        <label
                            for="logo-image"
                            class="tw-text-white tw-p-2 tw-bg-blue-500 tw-opacity-100 tw-cursor-pointer tw-rounded-lg"
                            >{{ $t("browseFile") }}</label
                        >
                        <input
                            type="file"
                            id="logo-image"
                            ref="logoimage"
                            style="display:none"
                            @change="onFileChange"
                        />
                    </div>
                </div>
                <div class="tw-w-full">
                    <div class="row tw-space-y-2">
                        <div class="col-md-12 tw-space-y-1">
                            <label>{{ $t("companyProfile.nameKH") }}</label>
                            <input
                                v-model="model.company_name_kh"
                                type="text"
                                class="form-control"
                                :class="{
                                    'is-invalid': errors.has('company_name_kh')
                                }"
                                :placeholder="$t('companyProfile.nameKH')"
                            />
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('company_name_kh')"
                            >
                                {{ errors.first("company_name_kh") }}
                            </div>
                        </div>
                        <div class="col-md-12 tw-space-y-1">
                            <label class="required">{{
                                $t("companyProfile.nameEN")
                            }}</label>
                            <input
                                v-model="model.company_name_en"
                                type="text"
                                class="form-control"
                                :class="{
                                    'is-invalid': errors.has('company_name_en')
                                }"
                                :placeholder="$t('companyProfile.nameEN')"
                            />
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('company_name_en')"
                            >
                                {{ errors.first("company_name_en") }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6 tw-space-y-1">
                    <label class="required">{{
                        $t("companyProfile.prefixCode")
                    }}</label>
                    <input
                        v-model="model.prefix_code"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': errors.has('prefix_code') }"
                        :placeholder="$t('companyProfile.prefixCode')"
                    />
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('prefix_code')"
                    >
                        {{ errors.first("prefix_code") }}
                    </div>
                </div>
                <div class="col-6 tw-space-y-1">
                    <label class="required">{{
                        $t("companyProfile.phone")
                    }}</label>
                    <input
                        v-model="model.company_phone"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': errors.has('company_phone') }"
                        :placeholder="$t('companyProfile.phone')"
                    />
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('company_phone')"
                    >
                        {{ errors.first("company_phone") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6 tw-space-y-1">
                    <label>{{ $t("companyProfile.email") }}</label>
                    <input
                        v-model="model.company_email"
                        type="email"
                        class="form-control"
                        :class="{ 'is-invalid': errors.has('company_email') }"
                        :placeholder="$t('companyProfile.email')"
                    />
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('company_email')"
                    >
                        {{ errors.first("company_email") }}
                    </div>
                </div>
                <div class="col-6 tw-space-y-1">
                    <label>{{ $t("companyProfile.website") }}</label>
                    <input
                        v-model="model.company_website"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': errors.has('company_website') }"
                        :placeholder="$t('companyProfile.website')"
                    />
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('company_website')"
                    >
                        {{ errors.first("company_website") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6 tw-space-y-1">
                    <label>{{ $t("companyProfile.city") }}</label>
                    <input
                        v-model="model.company_city"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': errors.has('company_city') }"
                        :placeholder="$t('companyProfile.city')"
                    />
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('company_city')"
                    >
                        {{ errors.first("company_city") }}
                    </div>
                </div>
                <div class="col-6 tw-space-y-1">
                    <label>{{ $t("companyProfile.zipCode") }}</label>
                    <input
                        v-model="model.zip_code"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': errors.has('zip_code') }"
                        :placeholder="$t('companyProfile.zipCode')"
                    />
                    <div class="invalid-feedback" v-if="errors.has('zip_code')">
                        {{ errors.first("zip_code") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6 tw-space-y-1">
                    <label>{{ $t("companyProfile.faxNumber") }}</label>
                    <input
                        v-model="model.company_fax"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': errors.has('company_fax') }"
                        :placeholder="$t('companyProfile.faxNumber')"
                    />
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('company_fax')"
                    >
                        {{ errors.first("company_fax") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <GmapMap
                        :center="center"
                        :zoom="zoom"
                        :options="{
                            streetViewControl: true
                        }"
                        map-type-id="roadmap"
                        class="tw-w-full tw-h-44 tw-mt-2"
                        @click="mapClick"
                    >
                        <GmapMarker
                            :clickable="true"
                            :draggable="true"
                            :position="marker"
                        />
                    </GmapMap>
                </div>
            </div>
            <div class="row">
                <div class="col-12 tw-space-y-1">
                    <label>{{ $t("companyProfile.address") }}</label>
                    <textarea
                        v-model="model.company_address"
                        rows="3"
                        class="form-control"
                        :placeholder="$t('companyProfile.address')"
                        :class="{ 'is-invalid': errors.has('company_address') }"
                    ></textarea>
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('company_address')"
                    >
                        {{ errors.first("company_address") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-flex tw-justify-end tw-space-x-3">
                    <ts-button
                        class="mr-2"
                        @click.prevent="() => $emit('cancel')"
                    >
                        {{ $t("cancel") }}
                    </ts-button>
                    <ts-button
                        v-if="!isUpdate"
                        color="primary"
                        @click.prevent="onSave"
                        >{{ $t("save") }}</ts-button
                    >
                    <ts-button
                        v-if="isUpdate"
                        color="primary"
                        @click.prevent="onUpdate"
                        >{{ $t("update") }}</ts-button
                    >
                </div>
            </div>
        </div>
    </ts-loading-banner>
</template>

<script>
import { isEmpty } from "lodash";
import { mapState } from "vuex";
import { Errors } from "form-backend-validation";

export default {
    name: "companyForm",
    data() {
        return {
            waiting: false,
            errors: new Errors(),
            showBrowseFileBotton: false,
            logo_file: "",
            logo_file_src: require("../../../assets/default-image.jpg"),
            zoom: 7,
            center: { lat: 12.565679, lng: 104.990967 },
            marker: { lat: undefined, lng: undefined },
            model: {
                company_id: null,
                prefix_code: null,
                company_name_kh: null,
                company_name_en: null,
                company_address: null,
                company_phone: null,
                company_email: null,
                company_website: null,
                company_logo: null,
								geo_location: {
										lat: "",
                    lng: ""
								}
            }
        };
    },
    computed: {
        ...mapState("corporate/companyProfile", ["edit_data"]),
        isUpdate() {
            return !isEmpty(this.edit_data);
        }
    },
    created() {
        this.setEditData();
    },
    methods: {
        onFileChange() {
            const file = this.$refs.logoimage.files[0];
            let reader = new FileReader();
            reader.addEventListener(
                "load",
                function() {
                    this.logo_file_src = reader.result;
                }.bind(this),
                false
            );
            if (file) {
                if (/\.(jpe?g|png)$/i.test(file.name)) {
                    reader.readAsDataURL(file);
                    this.logo_file = file;
                } else {
                    this.logo_file_src = require("../../../assets/default-image.jpg");
                    this.$toasted.error("invalid file type!");
                    this.$refs.logo_file.value = "";
                }
            }
        },
        async onSave() {
            this.waiting = true;
            this.$store
                .dispatch("corporate/companyProfile/store", this.model)
                .then(async response => {
                    await this.uploadLogo(response.company_id);
                    this.$notify({ type: "success", text: response.message });
                    this.$emit("fetchData");
                    this.$emit("cancel");
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                    this.errors = new Errors(error.errors);
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        async onUpdate() {
            this.waiting = true;
            this.$store
                .dispatch("corporate/companyProfile/update", {
                    id: this.model.company_id,
                    data: this.model
                })
                .then(async response => {
                    await this.uploadLogo(this.model.company_id);
                    this.$notify({ type: "success", text: response.message });
                    this.$emit("cancel");
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                    this.errors = new Errors(error.errors);
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        mapClick(value) {
						this.marker.lat = value.latLng.lat();
            this.marker.lng = value.latLng.lng();
						this.model.geo_location.lat = value.latLng.lat();
						this.model.geo_location.lng = value.latLng.lng();
        },
        setEditData() {
            if (!isEmpty(this.edit_data)) {
                this.model.company_id = this.edit_data.company_id;
                this.model.prefix_code = this.edit_data.prefix_code;
                this.model.company_name_kh = this.edit_data.company_name_kh;
                this.model.company_name_en = this.edit_data.company_name_en;
                this.model.company_address = this.edit_data.company_address;
                this.model.company_phone = this.edit_data.company_phone;
                this.model.company_email = this.edit_data.company_email;
                this.model.company_website = this.edit_data.company_website;
                this.model.company_logo = this.edit_data.company_logo;

								if(this.edit_data.geo_location) {
									this.center.lat = this.edit_data.geo_location[0].lat
									this.center.lng = this.edit_data.geo_location[0].lng
	
									this.marker.lat = this.edit_data.geo_location[0].lat
									this.marker.lng = this.edit_data.geo_location[0].lng

									this.model.geo_location.lat = this.edit_data.geo_location[0].lat
									this.model.geo_location.lng = this.edit_data.geo_location[0].lng
								}

                if (this.edit_data.company_logo) {
                    this.logo_file_src = this.edit_data.company_logo;
                }
            }
        },
        async uploadLogo(companyId) {
            if ((this.logo_file instanceof File) | Blob) {
                let formData = new FormData();
                formData.append("file", this.logo_file);
                formData.append("company_id", companyId);
                let response = await this.$store.dispatch(
                    "corporate/companyProfile/upload",
                    formData
                );
                this.model.company_logo = response;
            }
            return "";
        }
    }
};
</script>
